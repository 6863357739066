<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <base-material-card
      color="primary"
      icon="mdi-group"
      title="Listado de grupos"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="grupos"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-spacer />
            <v-dialog
              v-model="dialog"
              max-width="1000px"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  fab
                  dark
                  class="mb-2"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                  >
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="editedItem.grupo"
                            outlined
                            :rules="[v => !!v || 'El nombre es requerido']"
                            label="Nombre"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="editedItem.valor"
                            outlined
                            :suffix="currency"
                            :rules="valorRules"
                            label="Valor suscripción"
                            hint="Ingresar monto con el 21% de impuestos ya agregado. Siempre agregar dos decimales, aunque sean ceros. Formato de ejemplo: 1.345,00"
                            persistent-hint
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-select
                            v-model="editedItem.estado"
                            outlined
                            :items="estados"
                            item-text="text"
                            item-value="value"
                            :rules="[v => !!v || 'El estado es requerido']"
                            label="Estado"
                            required
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-select
                            v-model="editedItem.es_renovacion"
                            outlined
                            :items="opciones"
                            item-text="text"
                            item-value="value"
                            :rules="[v => !!v || 'Seleccione una opción']"
                            label="¿Es renovación de otro grupo?"
                            required
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                        >
                          <v-select
                            v-if="editedItem.es_renovacion === 'Si'"
                            v-model="editedItem.grupo_padre"
                            outlined
                            :items="grupos_padre"
                            item-text="grupo"
                            item-value="id"
                            :rules="[v => !!v || 'Seleccione una opción']"
                            label="Grupo padre"
                            required
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.valor="{ item }">
          {{ item.valor }} €
        </template>
        <template v-slot:item.estado="{ item }">
          <v-chip
            :color="getColor(item.estado)"
            dark
          >
            {{ item.estado }}
          </v-chip>
        </template>
        <template v-slot:item.acciones="{ item }">
          <v-icon
            small
            class="mr-2"
            color="success"
            @click="editItem(item)"
          >
            mdi-pencil-outline
          </v-icon>
          <v-icon
            small
            color="error"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initialize"
          >
            Recargar
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import GruposApi from '@/services/api/Grupos'

  export default {
    name: 'Grupos',

    components: {
    },

    data () {
      return {
        valid: true,
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        dialog: false,
        grupos: [],
        grupos_padre: [],
        editedIndex: -1,
        lazy: false,
        currency: '€',
        estados: [
          { value: 'ACTIVO', text: 'ACTIVO' },
          { value: 'INACTIVO', text: 'INACTIVO' },
        ],
        opciones: [
          { value: 'Si', text: 'Si' },
          { value: 'No', text: 'No' },
        ],
        headers: [
          {
            text: 'Nombre',
            align: 'center',
            sortable: true,
            value: 'grupo',
          },
          {
            text: 'Suscripción',
            align: 'center',
            sortable: true,
            value: 'valor',
          },
          {
            text: 'Estado',
            align: 'center',
            sortable: true,
            value: 'estado',
          },
          {
            text: 'Acciones',
            align: 'center',
            value: 'acciones',
            sortable: false,
          },
        ],
        editedItem: {
          id: '',
          grupo: '',
          valor: '',
          estado: '',
          es_renovacion: '',
        },
        defaultItem: {
          id: '',
          grupo: '',
          valor: '',
          estado: '',
          es_renovacion: '',
        },
        valorRules: [
          v => {
            const pattern = /^\d{1,3}(\.\d{3})*,\d{2}$/
            return pattern.test(v) || 'Formato no válido'
          },
        ],
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo grupo' : 'Modificar grupo'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      fetchGrupos () {
        GruposApi.getAllGrupos()
          .then(grupos => {
            this.grupos = grupos
            grupos.forEach(grupo => {
              if (grupo.grupo_padre === 0) {
                this.grupos_padre.push(grupo)
              }
            })
          })
          .catch(error => console.log(error))
      },
      initialize () {
        this.fetchGrupos()
      },

      editItem (item) {
        this.editedIndex = this.grupos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        const index = this.grupos.indexOf(item)
        if (confirm('¿Realmente desea eliminar este registro?')) {
          GruposApi.deleteGrupo(item.id)
            .then(response => {
              this.grupos.splice(index, 1)
              this.text = 'Registro eliminado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        if (this.$refs.form.validate()) {
          if (this.editedIndex > -1) {
            const updGrupos = this.editedItem
            const indexItem = this.editedIndex
            GruposApi.updateGrupo(this.editedItem.id, this.editedItem)
              .then(response => {
                Object.assign(this.grupos[indexItem], updGrupos)
                this.text = 'Registro modificado correctamente. Se recargará la página...'
                this.snackbar = true
                this.colorSnak = 'success'
                this.editedIndex = -1
                setTimeout(() => { location.reload() }, 2000)
              })
              .catch(error => {
                console.log(error)
                this.text = 'El registro no puede ser modificado en este momento'
                this.colorSnak = 'error'
                this.snackbar = true
                this.editedIndex = -1
              })
          } else {
            const addGrupos = this.editedItem
            GruposApi.addGrupo(this.editedItem)
              .then(response => {
                addGrupos.id = response.id
                this.grupos.push(addGrupos)
                this.text = 'Registro creado correctamente. Se recargará la página...'
                this.colorSnak = 'success'
                this.snackbar = true
                this.editedIndex = -1
                setTimeout(() => { location.reload() }, 2000)
              })
              .catch(error => {
                console.log(error)
                this.text = 'El registro no puede ser creado en este momento'
                this.colorSnak = 'error'
                this.snackbar = true
              })
          }
          this.close()
        }
      },

      getColor (estado) {
        if (estado === 'ACTIVO') return 'green'
        else return 'red'
      },
    },
  }
</script>
