import axios from 'axios'

const path = '/grupo'

export function getAllGrupos () {
  return axios.get(path)
  .then(response => {
    return response.data
  })
}

export function updateGrupo (id, grupo) {
  return axios.put(path + '/' + id, grupo)
  .then(response => {
      return response.data
    })
}

export function addGrupo (grupo) {
  return axios.post(path, grupo)
  .then(response => {
    return response.data
  })
}

export function deleteGrupo (id) {
  return axios.delete(path + '/' + id)
  .then(response => {
      return response.data
    })
}

export function getGruposActivos () {
  return axios.get(path + '/activos')
  .then(response => {
    return response.data
  })
}

export function getGruposActivosNoRenovaciones () {
  return axios.get(path + '/activos-norenovaciones')
  .then(response => {
    return response.data
  })
}

export function getGruposActivosRenovaciones () {
  return axios.get(path + '/activos-renovaciones')
  .then(response => {
    return response.data
  })
}

export function getGrupoPadreConRenovaciones (id) {
  return axios.get(path + '/grupo-con-renovaciones/' + id)
  .then(response => {
    return response.data
  })
}

/* export function getOneGrupo (id) {
    return axios.get('/grupo/' + id)
    .then(response => {
        return response.data
      })
} */

export default {
  getAllGrupos,
  updateGrupo,
  addGrupo,
  deleteGrupo,
  getGruposActivos,
  getGruposActivosNoRenovaciones,
  getGruposActivosRenovaciones,
  getGrupoPadreConRenovaciones,
  // getOneGrupo,
}
